/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - queryAllModelsPaginated.ts
 */

import { Model as ModelRedux } from './Model';
import {
  getQueryAllModelsKey,
}                              from './queryKeys';
import {
  getQueryAllModelsPaginatedFn,
}                              from './queryFunctions';
import {
  CommonQueryOptions,
  ResponseHeaders,
}                              from './types';
import {
  DefaultError,
  InfiniteData,
  InfiniteQueryObserverOptions,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
}                              from '@tanstack/react-query';
import {
  queryClient,
}                              from './QueryClient';
import Collection              from '../Collection';
import React                   from 'react';

export interface GetAllModelsPaginatedOptions extends CommonQueryOptions {
  pageSize?: number;
}

function getQueryAllModelsPaginatedArguments<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsPaginatedOptions = {},
) {
  const {
          params    = {},
          relations = [],
          auth      = true,
          pageSize,
          ...queryOptions
        }            = options;
  // Assemble params
  const mergedParams = { ...params, with: relations };

  return {
    ...queryOptions,
    initialPageParam: { page: 1 },
    getNextPageParam: (previous) => {
      if (!previous.headers['content-range']) {
        return undefined;
      }

      return previous.headers['content-range'].to < previous.headers['content-range'].total
             ? { page: previous.headers['content-range'].page + 1 }
             : undefined;
    },
    queryKey        : getQueryAllModelsKey(modelType, mergedParams),
    queryFn         : getQueryAllModelsPaginatedFn(
      modelType,
      pageSize ?? 250,
      mergedParams,
      { auth: options.auth, debug: options.debug },
    ),
  } as InfiniteQueryObserverOptions<{ models: Collection<Model> | null; headers: ResponseHeaders },
    DefaultError,
    InfiniteData<{ models: Collection<Model> | null; headers: ResponseHeaders }>, {
    models: Collection<Model> | null;
    headers: ResponseHeaders
  }, QueryKey, { page: number }>;
}

/**
 * Identical to `useAllModels`, but load all models progressively, as the API paginates them
 *
 * https://codesandbox.io/s/react-query-useinfinitequery-autoload-f5ykj?file=/src/App.js
 *
 * @param modelType
 * @param options
 */
export function useAllModelsPaginated<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsPaginatedOptions = {},
): Omit<UseInfiniteQueryResult, 'data' | 'fetchNextPage'> & { models: Collection<Model>, headers: ResponseHeaders } {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...queryState } = useInfiniteQuery(
    getQueryAllModelsPaginatedArguments(modelType, options));

  React.useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [ fetchNextPage, hasNextPage, isFetchingNextPage ]);

  const models = React.useMemo(() => {
    return Collection.from(data?.pages.flatMap(page => page.models ?? []) ?? []);
  }, [ data ]);

  return {
    models : models,
    headers: data?.pages.at(-1)?.headers ?? {},
    isFetchingNextPage,
    hasNextPage,
    ...queryState,
  };
}

export async function getAllModelsPaginated<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  options: GetAllModelsPaginatedOptions = {},
) {
  return queryClient.fetchInfiniteQuery(getQueryAllModelsPaginatedArguments(modelType, options));
}