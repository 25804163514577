/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - ProductCategory.ts
 */

import i18n        from 'i18next';
import Collection  from 'library/Collection';
import {
  DateTime,
}                  from 'luxon';
import {
  Format,
  InventoryPicture,
  InventoryResource,
  LoopConfiguration,
  PricelistProductsCategory,
  Product,
  ScreenType,
}                  from 'models';
import {
  Model,
}                  from 'library/Model';
import {
  ModelPersistAction,
}                  from 'library/Model/types';
import {
  LocalizedKey,
}                  from 'library/types';
import {
  ProductCategoryAttachment,
}                  from './Attachment';
import {
  ProductCategoryImpressionsModel,
}                  from './ImpressionsModel';
import ProductType from './enums/ProductType';
import {
  MediaType,
}                  from './enums/MediaType';

export interface IProductCategory {
  id: number,
  type_id: number,
  name_en: string,
  name_fr: string,
  type: ProductType,
  allowed_media_types: MediaType[],
  allows_audio: boolean,
  allows_motion: boolean,
  production_cost: number,
  programmatic_price: number,
  screen_size_in: number | null,
  screen_type_id: number | null,

  created_at: DateTime;
  updated_at: DateTime;

  products: Collection<Product>

  format_id: number | null,
  format: Format,

  inventory_resource_id: number,
  inventory_resource: InventoryResource,

  cover_picture_id: number | null,
  cover_picture: InventoryPicture | null,
  pictures: Collection<InventoryPicture>,

  impressions_models: Collection<ProductCategoryImpressionsModel>;
  attachments: Collection<ProductCategoryAttachment>;
  loop_configurations: Collection<LoopConfiguration>;
  screen_type: ScreenType | null,

  pricing: PricelistProductsCategory,
}

class ProductCategoryModel extends Model<IProductCategory> {
  _slug = 'product-category';

  basePath = '/v1/product-categories';

  attributesTypes: { [attr in keyof IProductCategory]?: (sourceAttr: any) => IProductCategory[attr] } = {
    created_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    pricing            : Model.make(PricelistProductsCategory),
    format             : Model.make(Format),
    products           : Collection.ofType(Product).make,
    impressions_models : Collection.ofType(ProductCategoryImpressionsModel).make,
    attachments        : Collection.ofType(ProductCategoryAttachment).make,
    loop_configurations: Collection.ofType(LoopConfiguration).make,
    inventory_resource : Model.make(InventoryResource),
    pictures           : Collection.ofType(InventoryPicture).make,
    screen_type        : Model.make(ScreenType),
  };

  key: keyof IProductCategory = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IProductCategory | string)[] } = {
    create: [],
    save  : [
      'name_en',
      'name_fr',
      'type',
      'format_id',
      'allowed_media_types',
      'allows_audio',
      'allows_motion',
      'cover_picture_id',
      'screen_type_id',
      'screen_size_in',
      'production_cost',
      'programmatic_price',
    ],
  };
}

export class ProductCategory extends ProductCategoryModel implements IProductCategory {
  attachments!: Collection<ProductCategoryAttachment>;

  created_at!: DateTime;

  external_id!: number;

  type!: ProductType;

  format_id!: number | null;

  format!: Format;

  id!: number;

  impressions_models!: Collection<ProductCategoryImpressionsModel>;

  inventory_resource_id!: number;

  inventory_resource!: InventoryResource;

  name_en!: string;

  name_fr!: string;

  allowed_media_types!: MediaType[];

  allows_audio!: boolean;

  products!: Collection<Product>;

  type_id!: number;

  updated_at!: DateTime;

  pricing!: PricelistProductsCategory;

  loop_configurations!: Collection<LoopConfiguration>;

  production_cost!: number;

  programmatic_price!: number;

  allows_motion!: boolean;

  screen_size_in!: number | null;

  screen_type!: ScreenType | null;

  screen_type_id!: number | null;

  cover_picture_id!: number | null;

  cover_picture!: InventoryPicture | null;

  pictures!: Collection<InventoryPicture>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static localizedNameKey(): LocalizedKey<'name'> {
    return `name_${ i18n.language }` as LocalizedKey<'name'>;
  }

  localizedName() {
    return this[ProductCategory.localizedNameKey()];
  }

  getName() {
    return this.localizedName();
  }
}
