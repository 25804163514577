/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Product.ts
 */

import i18n                                                       from 'i18next';
import Collection                                                 from 'library/Collection';
import { DateTime }                                               from 'luxon';
import {
  Format,
  ILocation,
  InventoryPicture,
  Location,
  LoopConfiguration,
  OpeningHours,
  Pricelist,
  PricelistProduct,
  PricelistProductsCategory,
  ProductCategory,
  Property,
  PropertyType,
  ScreenType,
  Unavailability,
}                                                                 from 'models';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { LocalizedKey }                                           from 'library/types';
import { ProductAttachment }                                      from './Attachment';
import { ProductImpressionsModel }                                from './ImpressionsModel';
import { PricingType }                                            from './enums/PricingType';
import { formatNumber }                                           from 'library/utils/formatNumber';
import { makeRequest }                                            from 'library/Request/Request';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { InventoryResource }                                      from './InventoryResource';
import { MediaType }                                              from './enums/MediaType';

interface ProductWarnings {
  missing_locations: boolean;
}

export interface IProduct {
  id: number,
  name_en: string,
  name_fr: string,

  is_sellable: boolean,
  quantity: number,
  unit_price: number,
  is_bonus: boolean,

  allowed_media_types: MediaType[],
  allows_audio: boolean | null,
  allows_motion: boolean | null,
  production_cost: number | null,
  programmatic_price: number | null,
  screen_size_in: number | null,
  screen_type_id: number | null,

  primary_broadcast_location_id: number | null,

  linked_product_id: boolean,

  inventory_resource_id: number,
  inventory_resource: InventoryResource,

  property_id: number,
  property: Property,

  format_id: number | null,
  format: Format,

  site_type_id: number | null,
  site_type: PropertyType | null,

  category_id: number,
  category: ProductCategory,

  opening_hours: Collection<OpeningHours>,

  cover_picture_id: number | null,
  cover_picture: InventoryPicture | null,
  pictures: Collection<InventoryPicture>,
  pictures_count: number,
  mockups_count: number,

  warnings: ProductWarnings,
  locations: Collection<Location>,
  attachments: Collection<ProductAttachment>,
  impressions_models: Collection<ProductImpressionsModel>,
  loop_configurations: Collection<LoopConfiguration>;
  screen_type: ScreenType | null,

  pricelist: Pricelist | null,
  pricing: PricelistProduct,
  unavailabilities: Collection<Unavailability>

  notes: string,

  created_at: DateTime,
  updated_at: DateTime,
}

class ProductModel extends Model<IProduct> {
  _slug = 'product';

  basePath = '/v1/products';

  attributesTypes: { [attr in keyof IProduct]?: (sourceAttr: any) => IProduct[attr] } = {
    created_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at         : (d) => DateTime.fromISO(d),
    impressions_models : Collection.ofType(ProductImpressionsModel).make,
    inventory_resource : Model.make(InventoryResource),
    locations          : Collection.ofType(Location).make,
    category           : Model.make(ProductCategory),
    cover_picture      : Model.make(InventoryPicture),
    property           : Model.make(Property),
    format             : Model.make(Format),
    site_type          : Model.make(PropertyType),
    pictures           : Collection.ofType(InventoryPicture).make,
    pricing            : Model.make(PricelistProduct),
    pricelist          : Model.make(Pricelist),
    attachments        : Collection.ofType(ProductAttachment).make,
    loop_configurations: Collection.ofType(LoopConfiguration).make,
    unavailabilities   : Collection.ofType(Unavailability).make,
    opening_hours      : Collection.ofType(OpeningHours).make,
    screen_type        : Model.make(ScreenType),
  };

  key: keyof IProduct = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IProduct | string)[] } = {
    create: [],
    save  : [
      'is_sellable',
      'format_id',
      'site_type_id',
      'allowed_media_types',
      'allows_audio',
      'production_cost',
      'programmatic_price',
      'allows_motion',
      'screen_type_id',
      'screen_size_in',
      'cover_picture_id',
      'primary_broadcast_location_id',
      'notes',
    ],
  };

}

export class Product extends ProductModel implements IProduct {
  attachments!: Collection<ProductAttachment>;

  category!: ProductCategory;

  category_id!: number;

  created_at!: DateTime;

  format_id!: number | null;

  format!: Format;

  id!: number;

  impressions_models!: Collection<ProductImpressionsModel>;

  inventory_resource_id!: number;

  inventory_resource!: InventoryResource;

  is_bonus!: boolean;

  is_sellable!: boolean;

  locations!: Collection<Location>;

  name_en!: string;

  name_fr!: string;

  pricelist!: Pricelist;

  property!: Property;

  property_id!: number;

  quantity!: number;

  site_type_id!: number | null;

  site_type!: PropertyType | null;

  unit_price!: number;

  updated_at!: DateTime;

  loop_configurations!: Collection<LoopConfiguration>;

  pricing!: PricelistProduct;

  opening_hours!: Collection<OpeningHours>;

  warnings!: ProductWarnings;

  unavailabilities!: Collection<Unavailability>;

  allowed_media_types!: MediaType[];

  allows_audio!: boolean | null;

  linked_product_id!: boolean;

  production_cost!: number | null;

  programmatic_price!: number | null;

  notes!: string;

  allows_motion!: boolean | null;

  screen_size_in!: number | null;

  screen_type!: ScreenType | null;

  screen_type_id!: number | null;

  cover_picture!: InventoryPicture | null;

  cover_picture_id!: number | null;

  primary_broadcast_location_id!: number | null;

  pictures!: Collection<InventoryPicture>;

  pictures_count!: number;

  mockups_count!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }


  static localizedNameKey(): LocalizedKey<'name'> {
    return `name_${ i18n.language }` as LocalizedKey<'name'>;
  }

  localizedName() {
    return this[Product.localizedNameKey()];
  }

  getName() {
    return this.localizedName();
  }

  getPricing(pricelist?: Pricelist | null): PricelistProduct | PricelistProductsCategory | undefined {
    return pricelist?.products_pricings?.findBy('product_id', this.getKey()) ??
      pricelist?.categories_pricings?.findBy('products_category_id', this.category_id);
  }

  getPriceString(pricelist?: Pricelist | null) {
    if (!pricelist) {
      return `${ this.quantity } ⨉ ${ formatNumber(this.unit_price, { currency: true }) }`;
    }

    const pricing = this.getPricing(pricelist);

    if (pricing) {
      switch (pricing.pricing) {
        case PricingType.unit:
          return `${ this.quantity } ⨉ ${ formatNumber(pricing.value, { currency: true }) }`;
        case PricingType.unitProduct:
          return `${ formatNumber(pricing.value, { currency: true }) }`;
        case PricingType.cpm:
          return `${ formatNumber(pricing.value, { currency: true }) } CPM`;
      }
    }
  }

  syncLocations(locations: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/locations');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<ILocation[]>(route, params, { locations })
      .then(({ data }) => {
        this.setAttributes({
          locations: data as Collection<Location>,
        });

        return data;
      });
  }
}

export class CampaignProduct extends Product {
  _slug = 'campaign-product';

  basePath = '/v2/campaigns/{campaign_id}/products';

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}

export class PublicProduct extends Product {
  basePath = '/public/v1/products';
}
