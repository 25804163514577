// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - TextButton.scss
 */.text-button{display:inline;cursor:default;text-underline-offset:.2rem;text-decoration-thickness:5px;--text-button-color: var(--highlight-color-rgb)}.text-button:not(.disabled):hover{text-decoration:underline}.text-button:not(.disabled):focus,.text-button:not(.disabled):focus-visible{outline:none;text-decoration:underline}.text-button:not(.disabled):active{text-decoration:underline;color:rgb(var(--text-button-color));text-underline-color:rgb(var(--text-button-color))}`, "",{"version":3,"sources":["webpack://./src/components/Button/TextButton.scss"],"names":[],"mappings":"AAAA;;;;;;;EAAA,CASA,aACE,cAAA,CACA,cAAA,CAEA,2BAAA,CACA,6BAAA,CAEA,+CAAA,CAGE,kCACE,yBAAA,CAGF,4EACE,YAAA,CACA,yBAAA,CAGF,mCACE,yBAAA,CACA,mCAAA,CACA,kDAAA","sourcesContent":["/*!\n * Copyright 2023 (c) Neo-OOH - All Rights Reserved\n * Unauthorized copying of this file, via any medium is strictly prohibited\n * Proprietary and confidential\n * Written by Valentin Dufois <vdufois@neo-ooh.com>\n *\n * @neo/connect - TextButton.scss\n */\n\n.text-button {\n  display                   : inline;\n  cursor                    : default;\n\n  text-underline-offset     : .2rem;\n  text-decoration-thickness : 5px;\n\n  --text-button-color       : var(--highlight-color-rgb);\n\n  &:not(.disabled) {\n    &:hover {\n      text-decoration : underline;\n    }\n\n    &:focus, &:focus-visible {\n      outline         : none;\n      text-decoration : underline;\n    }\n\n    &:active {\n      text-decoration      : underline;\n      color                : rgb(var(--text-button-color));\n      text-underline-color : rgb(var(--text-button-color));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
