/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - IndexSet.ts
 */

import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { DateTime }                                               from 'luxon';
import ReportStatus                                               from './enums/ReportStatus';
import Collection                                                 from 'library/Collection';
import { Extract, IndexSetTemplate, IndexSetValue, Property }     from './index';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';

export interface IndexSetMetadata {
  executionTimeMs: number,

  error: Record<string, any> | null
}

export interface IIndexSet {
  id: number,
  template_id: number,
  property_id: number,
  primary_extract_id: number | null,
  reference_extract_id: number | null,
  metadata: IndexSetMetadata
  status: ReportStatus

  requested_at: DateTime,
  requested_by: number | null,
  processed_at: DateTime,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  template: IndexSetTemplate,
  property: Property,
  values: Collection<IndexSetValue>,
  primary_extract: Extract,
  reference_extract: Extract,
}

class IndexSetModel extends Model<IIndexSet> {
  _slug = 'index-set';

  basePath = '/v1/demographics/index-sets';

  attributesTypes: { [attr in keyof IIndexSet]?: (sourceAttr: any) => IIndexSet[attr] } = {
    template         : Model.make(IndexSetTemplate),
    property         : Model.make(Property),
    values           : Collection.ofType(IndexSetValue).make,
    primary_extract  : Model.make(Extract),
    reference_extract: Model.make(Extract),
    requested_at     : (d) => DateTime.fromISO(d),
    processed_at     : (d) => DateTime.fromISO(d),
    deleted_at       : (d) => DateTime.fromISO(d),
  };

  key: keyof IIndexSet = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IIndexSet | string)[] } = {
    create: [ 'property_id', 'template_id', 'primary_extract_id', 'reference_extract_id' ],
    save  : [],
  };
}

export class IndexSet extends IndexSetModel implements IIndexSet {
  id!: number;

  template_id!: number;

  property_id!: number;

  primary_extract_id!: number;

  reference_extract_id!: number;

  metadata!: IndexSetMetadata;

  status!: ReportStatus;

  requested_at!: DateTime<true>;

  requested_by!: number | null;

  processed_at!: DateTime<true>;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  template!: IndexSetTemplate;

  property!: Property;

  values!: Collection<IndexSetValue>;

  primary_extract!: Extract;

  reference_extract!: Extract;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async forceProcess() {
    const path       = preparePathForSingleModelAction(this.basePath, `/_force_process`);
    const pathParams = preparePathParameters(path, this);
    const route      = makeRoute(path, HTTPMethod.post);

    await makeRequest<IIndexSet>(route, pathParams);

    return Promise.all([
      this.invalidate(),
      this.invalidateAll(),
    ]);
  }
}
