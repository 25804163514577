/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Asset.ts
 */

import { DateTime }                                               from 'luxon';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { AssetType }                                              from './enums/AssetType';
import { AssetProperty }                                          from './enums/AssetProperty';
import { HardwareProvider }                                       from './HardwareProvider';
import { preparePathForSingleModelAction, preparePathParameters } from '../library/Model/utils';
import { makeRoute }                                              from '../library/modelsUtils';
import { HTTPMethod }                                             from '../library/Request';
import { makeRequest }                                            from '../library/Request/Request';
import { HardwareProviderCapability }                             from './enums/HardwareProviderCapability';

export interface IAsset {
  id: number,
  uuid: string,
  type: AssetType | string,
  name: string,

  provider_id: number | null,
  external_id: string | null,

  properties: Record<AssetProperty | string, any>

  last_refresh_at: DateTime,

  created_at: DateTime,
  created_by: number,
  updated_at: DateTime
  updated_by: number,
  deleted_at: DateTime | null
  deleted_by: number | null,

  provider?: HardwareProvider,
}

class AssetModel extends Model<IAsset> {
  _slug = 'asset';

  basePath = '/v1/assets';

  attributesTypes: { [attr in keyof IAsset]?: (sourceAttr: any) => IAsset[attr] } = {
    last_refresh_at: (d) => DateTime.fromISO(d),
    created_at     : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at     : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    provider       : Model.make(HardwareProvider),
  };

  key = 'id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IAsset | string)[] } = {
    create: [ 'type', 'provider_id', 'external_id', 'name' ],
    save  : [],
  };
}

export class Asset extends AssetModel implements IAsset {
  id!: number;

  uuid!: string;

  type!: string;

  name!: string;

  provider_id!: number | null;

  external_id!: string | null;

  properties!: Record<string, any>;

  last_refresh_at!: DateTime<true>;

  created_at!: DateTime<true>;

  created_by!: number;

  updated_at!: DateTime<true>;

  updated_by!: number;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  provider!: HardwareProvider | undefined;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  refreshProperties(sync: boolean = false) {
    const path   = preparePathForSingleModelAction(this.basePath, '/_refresh');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params, {
      sync: sync,
    }).then(async response => {
      await this.invalidate();
      this.invalidateAll();
      return this;
    });
  }

  sendAction(action: HardwareProviderCapability | string, payload: any, sync: boolean = false) {
    const path   = preparePathForSingleModelAction(this.basePath, '/_send_action');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params, {
      action : action,
      payload: payload,
      sync   : sync,
    }).then(async response => {
      await this.invalidate();
      this.invalidateAll();
      return this;
    });
  }

  getCapabilities() {
    return (this.properties[AssetProperty.Capabilities] as (HardwareProviderCapability | string)[]) ?? [];
  }

  hasCapability(capability: HardwareProviderCapability | string) {
    return this.getCapabilities().includes(capability);
  }
}

export class PropertyAsset extends Asset {
  basePath = '/v1/properties/{property_id}/assets';
}