import hash                from 'hash-sum';
import cache               from 'memory-cache';
import { QueryParameters } from './Types';
import { AxiosResponse }   from 'axios';

/**
 * This is an opiniated cache adapter made for limiting duplicate requests.
 * It support storing, fetching and invalidating. Since it uses a memory cache in the back, no support for time invalidation is provided.
 */

const cleanUrl = (url: string) => url.replace(/^\/+|\/+$/g, '');

/**
 * @param {string} url
 * @param {Object} queryParameters
 */
const makeId = (url: string, queryParameters?: QueryParameters) => hash({ url, queryParameters: queryParameters ?? {} });

const cacheStore: Record<string, string[]> = {};

export const store = (url: string, queryParameters: QueryParameters, payload: AxiosResponse) => {
  url = cleanUrl(url);

  const rid       = makeId(url, queryParameters);
  cacheStore[url] = [ ...(cacheStore[url] ?? []), rid ];

  cache.put(rid, payload);
};

export const get = <T, >(url: string, queryParameters: QueryParameters): AxiosResponse<T> | null => {
  url = cleanUrl(url);

  return cache.get(makeId(url, queryParameters)) as AxiosResponse<T> | null;
};

export const invalidate = (url: string) => {
  url = cleanUrl(url);

  (cacheStore[url] ?? []).forEach(rid => cache.del(rid));
  delete cacheStore[url];
};

export const clear = () => {
  for (const entry in cacheStore) {
    delete cacheStore[entry];
  }

  cache.clear();
};
