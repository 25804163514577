/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Router.tsx
 */

import 'assets/styles/bootstrap.scss';
import Request                                                                  from 'library/Request';
import Main                                                                     from 'Main';
import React                                                                    from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Home                                                                     from 'scenes/Home';
import Loader                                                                   from './components/Loader/Loader';

const handleFailedLoad = (e: any) => {
  console.warn('Could not load chunk, reloading...', e);
  window.location.reload();
  return { Component: null };
};

function Router() {
  Request.init();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="*" element={ <Main/> }>
        <Route index path="*" element={ <Home/> }/>
        {/* Broadcast */ }
        <Route path="broadcast/*" lazy={ () => import('./scenes/Broadcast').catch(handleFailedLoad) }>
          <Route path="actors/:actor" lazy={ () => import('./scenes/Broadcast/Group').catch(handleFailedLoad) }/>
          <Route path="users/:actor" lazy={ () => import('./scenes/Actors/Actor').catch(handleFailedLoad) }/>
          <Route path="campaigns/:campaign" lazy={ () => import('./scenes/Broadcast/Campaigns').catch(handleFailedLoad) }/>
          <Route path="libraries/:library/*"
                 lazy={ () => import('./scenes/Broadcast/Libraries/Library').catch(handleFailedLoad) }/>
          <Route path="libraries/:library/contents/:content"
                 lazy={ () => import('./scenes/Broadcast/Libraries/Content').catch(handleFailedLoad) }/>
          <Route path="libraries/:library/contents/:content/batches/:batch"
                 lazy={ () => import('./scenes/Broadcast/Libraries/Content/Batch').catch(handleFailedLoad) }/>
        </Route>
        <Route path="broadcast/libraries/:library/schedule"
               lazy={ () => import('./scenes/Broadcast/Libraries/ContentScheduler/MultiContentsScheduler').catch(handleFailedLoad) }/>
        <Route path="broadcast/libraries/:library/contents/:content/schedule"
               lazy={ () => import('./scenes/Broadcast/Libraries/ContentScheduler/SingleContentScheduler').catch(handleFailedLoad) }/>
        <Route path="broadcast/campaigns/new"
               lazy={ () => import('./scenes/Broadcast/Campaigns/NewCampaign').catch(handleFailedLoad) }/>
        <Route path="broadcast/contents/:content"
               lazy={ () => import('./scenes/Broadcast/ContentRedirection').catch(handleFailedLoad) }/>

        {/* Networks */ }
        <Route path="networks">
          <Route index lazy={ () => import('./scenes/Networks').catch(handleFailedLoad) }/>
          <Route path=":network/*" lazy={ () => import('./scenes/Networks/Network').catch(handleFailedLoad) }>
            <Route path="containers/:container"
                   lazy={ () => import('./scenes/Networks/Network/ContainerView').catch(handleFailedLoad) }/>
            <Route path="locations/:location"
                   lazy={ () => import('./scenes/Networks/Network/LocationView').catch(handleFailedLoad) }/>
          </Route>
        </Route>
        <Route path="networks-monitoring/*" lazy={ () => import('./scenes/NetworksMonitoring').catch(handleFailedLoad) }>
          <Route path=":group"
                 lazy={ () => import('./scenes/NetworksMonitoring/NetworksMonitoringGroup').catch(handleFailedLoad) }/>
        </Route>
        <Route path="broadcast-maintenance" lazy={ () => import('./scenes/BroadcastMaintenance').catch(handleFailedLoad) }/>

        {/* Dynamics */ }
        <Route path="weather-dynamic/*" lazy={ () => import('./scenes/Dynamics/Weather').catch(handleFailedLoad) }>
          <Route path={ 'create-content' }
                 lazy={ () => import('./scenes/Dynamics/Weather/ContentCreator').catch(handleFailedLoad) }/>
          <Route path={ ':bundleId' } lazy={ () => import('./scenes/Dynamics/Weather/WeatherBundle').catch(handleFailedLoad) }/>
        </Route>
        <Route path="news-dynamic/*" lazy={ () => import('./scenes/Dynamics/News').catch(handleFailedLoad) }>
          <Route path={ 'create-content' }
                 lazy={ () => import('./scenes/Dynamics/News/ContentCreator').catch(handleFailedLoad) }/>
        </Route>

        {/* Properties */ }
        <Route path="properties/*" lazy={ () => import('./scenes/Properties').catch(handleFailedLoad) }>
          <Route path="groups/:actor" lazy={ () => import('./scenes/Properties/Group').catch(handleFailedLoad) }/>
          <Route path="users/:actor" lazy={ () => import('./scenes/Actors/Actor').catch(handleFailedLoad) }/>
          <Route path=":property/*" lazy={ () => import('./scenes/Properties/PropertyRoot').catch(handleFailedLoad) }>
            <Route index lazy={ () => import('./scenes/Properties/Property').catch(handleFailedLoad) }/>
            <Route path="products/:product" lazy={ () => import('./scenes/Properties/Product').catch(handleFailedLoad) }/>
            <Route path="geographic-reports/:geoReport"
                   lazy={ () => import('./scenes/Properties/GeographicReport').catch(handleFailedLoad) }/>
            <Route path="extracts/:extract"
                   lazy={ () => import('./scenes/Properties/Extract').catch(handleFailedLoad) }/>
            <Route path="index-sets/:indexSet"
                   lazy={ () => import('./scenes/Properties/IndexSet').catch(handleFailedLoad) }/>
            <Route path="assets/:asset"
                   lazy={ () => import('./scenes/Assets/Asset').catch(handleFailedLoad) }/>
          </Route>
        </Route>

        {/* Product Categories */ }
        <Route path="products/*" lazy={ () => import('./scenes/ProductCategories').catch(handleFailedLoad) }>
          <Route path=":categoryId" lazy={ () => import('./scenes/ProductCategories/Category').catch(handleFailedLoad) }/>
        </Route>

        {/* Properties Resources */ }
        <Route path="properties-networks" lazy={ () => import('./scenes/PropertiesNetworks').catch(handleFailedLoad) }>
          <Route path=":network" lazy={ () => import('./scenes/PropertiesNetworks/PropertyNetwork').catch(handleFailedLoad) }/>
        </Route>
        <Route path="pricelists" lazy={ () => import('./scenes/Pricelists').catch(handleFailedLoad) }>
          <Route path=":pricelist" lazy={ () => import('./scenes/Pricelists/Pricelist').catch(handleFailedLoad) }/>
        </Route>
        <Route path="brands" lazy={ () => import('./scenes/Brands').catch(handleFailedLoad) }>
          <Route path=":brandId" lazy={ () => import('./scenes/Brands/BrandPage').catch(handleFailedLoad) }/>
        </Route>
        <Route path="property-types" lazy={ () => import('./scenes/PropertyTypes').catch(handleFailedLoad) }>
          <Route path=":propertyType" lazy={ () => import('./scenes/PropertyTypes/PropertyType').catch(handleFailedLoad) }/>
        </Route>
        <Route path="screen-types" lazy={ () => import('./scenes/ScreenTypes').catch(handleFailedLoad) }>
          <Route path=":screenType" lazy={ () => import('./scenes/ScreenTypes/ScreenType').catch(handleFailedLoad) }/>
        </Route>
        <Route path="property-tags" lazy={ () => import('./scenes/Tags').catch(handleFailedLoad) }/>
        <Route path="mobile-products" lazy={ () => import('./scenes/MobileProducts').catch(handleFailedLoad) }>
          <Route path=":mobileProduct" lazy={ () => import('./scenes/MobileProducts/MobileProduct').catch(handleFailedLoad) }/>
        </Route>

        {/* Inventories */ }
        <Route path="inventories" lazy={ () => import('./scenes/Inventories').catch(handleFailedLoad) }/>

        {/* Assets */ }
        <Route path="assets/:asset" lazy={ () => import('./scenes/Assets/Asset').catch(handleFailedLoad) }/>

        {/* Demographic Templates */ }
        <Route path="demographics/*" lazy={ () => import('./scenes/DemographicsTemplates').catch(handleFailedLoad) }>
          <Route path="geographic-reports-templates/:geoReportTemplate"
                 lazy={ () => import('./scenes/DemographicsTemplates/GeographicReportTemplate').catch(handleFailedLoad) }/>
          <Route path="extracts-templates/:extractTemplate"
                 lazy={ () => import('./scenes/DemographicsTemplates/ExtractTemplate').catch(handleFailedLoad) }/>
          <Route path="index-sets-templates/:indexSetTemplate"
                 lazy={ () => import('./scenes/DemographicsTemplates/IndexSetTemplate').catch(handleFailedLoad) }/>
        </Route>

        {/* Datasets */ }
        <Route path="datasets" lazy={ () => import('./scenes/Datasets').catch(handleFailedLoad) }>
          <Route path=":datasetVersion" lazy={ () => import('./scenes/Datasets/Version').catch(handleFailedLoad) }/>
        </Route>

        {/* Contracts */ }
        <Route path="contracts">
          <Route index lazy={ () => import('./scenes/Contracts').catch(handleFailedLoad) }/>
          <Route path=":contract/*" lazy={ () => import('./scenes/Contracts/Contract').catch(handleFailedLoad) }>
            <Route index lazy={ () => import('./scenes/Contracts/Contract/Home').catch(handleFailedLoad) }/>
            <Route path="performances" lazy={ () => import('./scenes/Contracts/Contract/Performances').catch(handleFailedLoad) }/>
            <Route path="proof-of-performances"
                   lazy={ () => import('./scenes/Contracts/Contract/ProofOfPerformances').catch(handleFailedLoad) }/>
            <Route path="screenshots" lazy={ () => import('./scenes/Contracts/Contract/Screenshots').catch(handleFailedLoad) }/>

            <Route path="flights/:flight/*" lazy={ () => import('./scenes/Contracts/Contract/Flight').catch(handleFailedLoad) }/>

            <Route path="flights/:flight/campaigns/:campaign"
                   lazy={ () => import('./scenes/Broadcast/Campaigns').catch(handleFailedLoad) }/>
            <Route path="campaigns/:campaign" lazy={ () => import('./scenes/Broadcast/Campaigns').catch(handleFailedLoad) }/>

            <Route path="libraries/:library"
                   lazy={ () => import('./scenes/Broadcast/Libraries/Library').catch(handleFailedLoad) }/>
          </Route>
          <Route path=":contract/schedule"
                 lazy={ () => import('./scenes/Contracts/Contract/ScheduleContract').catch(handleFailedLoad) }/>
        </Route>

        {/* Planner */ }
        <Route path="campaign-planner" lazy={ () => import('./scenes/CampaignPlanner').catch(handleFailedLoad) }/>

        {/* Advertiser */ }
        <Route path="advertisers" lazy={ () => import('./scenes/Advertisers').catch(handleFailedLoad) }>
          <Route path=":advertiser" lazy={ () => import('./scenes/Advertisers/Advertiser').catch(handleFailedLoad) }/>
        </Route>

        {/* Actors */ }
        <Route path="actors/*" lazy={ () => import('./scenes/Actors').catch(handleFailedLoad) }>
          <Route path=":actor" lazy={ () => import('./scenes/Actors/Actor').catch(handleFailedLoad) }/>
        </Route>

        {/* Settings */ }
        <Route path="settings/*" lazy={ () => import('./scenes/Settings').catch(handleFailedLoad) }>
          <Route index lazy={ () => import('./scenes/Settings/SettingsHome').catch(handleFailedLoad) }/>

          <Route path="headlines" lazy={ () => import('./scenes/Settings/Headlines').catch(handleFailedLoad) }/>
          <Route path="headlines/:headline"
                 lazy={ () => import('./scenes/Settings/Headlines/Headline').catch(handleFailedLoad) }/>

          <Route path="review-templates" lazy={ () => import('./scenes/Settings/ReviewTemplates').catch(handleFailedLoad) }/>

          <Route path="terms-of-service" lazy={ () => import('./scenes/Settings/TermsOfService').catch(handleFailedLoad) }/>

          <Route path="roles" lazy={ () => import('./scenes/Settings/Roles').catch(handleFailedLoad) }>
            <Route path="new" lazy={ () => import('./scenes/Settings/Roles/NewRole').catch(handleFailedLoad) }/>
            <Route path=":roleId" lazy={ () => import('./scenes/Settings/Roles/Role').catch(handleFailedLoad) }/>
          </Route>

          <Route path="access-tokens" lazy={ () => import('./scenes/Settings/AccessTokens').catch(handleFailedLoad) }>
            <Route path=":accessToken"
                   lazy={ () => import('./scenes/Settings/AccessTokens/AccessToken').catch(handleFailedLoad) }/>
          </Route>

          <Route path="broadcast" lazy={ () => import('./scenes/Settings/Broadcast').catch(handleFailedLoad) }/>

          <Route path="formats" lazy={ () => import('./scenes/Settings/Formats').catch(handleFailedLoad) }>
            <Route path=":formatId" lazy={ () => import('./scenes/Settings/Formats/Format').catch(handleFailedLoad) }/>
            <Route path="layouts/:layoutId" lazy={ () => import('./scenes/Settings/Formats/Layout').catch(handleFailedLoad) }/>
          </Route>

          <Route path="loop-configurations"
                 lazy={ () => import('./scenes/Settings/LoopConfigurations').catch(handleFailedLoad) }/>

          <Route path="broadcast-tags" lazy={ () => import('./scenes/Settings/BroadcastTags').catch(handleFailedLoad) }>
            <Route path=":tagId" lazy={ () => import('./scenes/Settings/BroadcastTags/BroadcastTag').catch(handleFailedLoad) }/>
          </Route>

          <Route path="display-types" lazy={ () => import('./scenes/Settings/DisplayTypes').catch(handleFailedLoad) }>
            <Route path=":displayTypeId"
                   lazy={ () => import('./scenes/Settings/DisplayTypes/DisplayType').catch(handleFailedLoad) }/>
          </Route>

          <Route path="inventories" lazy={ () => import('./scenes/Settings/Inventories').catch(handleFailedLoad) }>
            <Route path=":inventoryId" lazy={ () => import('./scenes/Settings/Inventories/Inventory').catch(handleFailedLoad) }/>
          </Route>

          <Route path="impressions" lazy={ () => import('./scenes/Settings/Impressions').catch(handleFailedLoad) }/>
          <Route path="markets" lazy={ () => import('./scenes/Settings/Markets').catch(handleFailedLoad) }/>

          <Route path="traffic" lazy={ () => import('./scenes/Settings/Traffic').catch(handleFailedLoad) }/>

          <Route path="contracts" lazy={ () => import('./scenes/Settings/Contracts').catch(handleFailedLoad) }/>

          <Route path="assets" lazy={ () => import('./scenes/Settings/Assets').catch(handleFailedLoad) }/>
          <Route path="hardware-providers" lazy={ () => import('./scenes/Settings/HardwareProviders').catch(handleFailedLoad) }>
            <Route path=":hardwareProvider"
                   lazy={ () => import('./scenes/Settings/HardwareProviders/HardwareProvider').catch(handleFailedLoad) }/>
          </Route>
        </Route>

        <Route path="me" lazy={ () => import('./scenes/MyAccount').catch(handleFailedLoad) }/>

        <Route path="dev" lazy={ () => import('./scenes/Dev').catch(handleFailedLoad) }/>
      </Route>,
    ),
  );

  return (
    <React.Suspense fallback={ <Loader/> }>
      <RouterProvider router={ router }/>
    </React.Suspense>
  );
}

export default Router;
