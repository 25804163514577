/*
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - queryKeys.ts
 */

import { Model as ModelRedux } from './Model';

export function getQueryAllModelsKey<Model extends ModelRedux<{}>>(
  modelType: new (...args: any) => Model,
  params: Record<string, any>,
) {
  return [ (new modelType())._slug, 'all', params ];
}

export function getQueryModelKey<Model extends ModelRedux<{}>>(
  modelType: new (...args: any) => Model,
  key: string | number,
  params: Record<string, any>,
) {
  return [ (new modelType())._slug, key, params ];
}

export function getQueryModelsKey<Model extends ModelRedux<{}>>(
  modelType: new (...args: any) => Model,
  keys: (string | number)[],
  params: Record<string, any>,
) {
  return [ (new modelType())._slug, keys, params ];
}
