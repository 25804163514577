// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Copyright 2023 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - OutdatedServiceWorkerRibbon.scss
 */.outdated-app-ribbon{display:flex;align-items:center;justify-content:center;gap:15px;height:40px;flex-shrink:0;border-bottom:1px solid var(--success);background-color:rgba(var(--success-rgb), 0.75);color:#fff;font-size:.889rem;font-weight:500}.outdated-app-ribbon .flat-button{height:25px;--flat-btn-color: 255, 255, 255;--flat-btn-front-color: var(--success);--flat-btn-front-hover-color: var(--success);--flat-btn-front-active-color: var(--success)}`, "",{"version":3,"sources":["webpack://./src/components/misc/OutdatedServiceWorkerRibbon.scss"],"names":[],"mappings":"AAAA;;;;;;;EAAA,CASA,qBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAEA,WAAA,CACA,aAAA,CAEA,sCAAA,CACA,+CAAA,CAEA,UAAA,CACA,iBAAA,CACA,eAAA,CAEA,kCACE,WAAA,CACA,+BAAA,CACA,sCAAA,CACA,4CAAA,CACA,6CAAA","sourcesContent":["/*!\n * Copyright 2023 (c) Neo-OOH - All Rights Reserved\n * Unauthorized copying of this file, via any medium is strictly prohibited\n * Proprietary and confidential\n * Written by Valentin Dufois <vdufois@neo-ooh.com>\n *\n * @neo/connect - OutdatedServiceWorkerRibbon.scss\n */\n\n.outdated-app-ribbon {\n  display          : flex;\n  align-items      : center;\n  justify-content  : center;\n  gap              : 15px;\n\n  height           : 40px;\n  flex-shrink      : 0;\n\n  border-bottom    : 1px solid var(--success);\n  background-color : rgba(var(--success-rgb), .75);\n\n  color            : #FFFFFF;\n  font-size        : .889rem;\n  font-weight      : 500;\n\n  .flat-button {\n    height                        : 25px;\n    --flat-btn-color              : 255, 255, 255;\n    --flat-btn-front-color        : var(--success);\n    --flat-btn-front-hover-color  : var(--success);\n    --flat-btn-front-active-color : var(--success);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
