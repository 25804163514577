/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Network.ts
 */

import { DateTime }                                                 from 'luxon';
import { BroadcasterConnection, Field, Location, NetworkContainer } from 'models';
import Collection                                                   from 'library/Collection';
import { Model }                                                    from 'library/Model';
import NetworkSettings                                              from './interfaces/NetworkSettings';
import { makeRequest }                                              from 'library/Request/Request';
import { makeRoute }                                                from 'library/modelsUtils';
import { preparePathForSingleModelAction, preparePathParameters }   from 'library/Model/utils';
import { HTTPMethod }                                               from 'library/Request';
import { handleDownload }                                           from 'library/utils';

export interface INetwork {
  id: number,
  uuid: string,
  connection_id: number,
  name: string,
  slug: string,
  color: string,
  settings: NetworkSettings,
  last_sync_at: DateTime | null,
  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  broadcaster_connection: BroadcasterConnection,
  properties_fields: Collection<Field>,
  containers: Collection<NetworkContainer>,
  locations: Collection<Location>,
}

class NetworkModel extends Model<INetwork> {
  _slug = 'network';

  basePath = '/v2/networks';

  attributesTypes: { [attr in keyof INetwork]?: (sourceAttr: any) => INetwork[attr] } = {
    broadcaster_connection: Model.make(BroadcasterConnection),
    properties_fields     : Collection.ofType(Field).make,
    containers            : Collection.ofType(NetworkContainer).make,
    locations             : Collection.ofType(Location).make,
    last_sync_at          : (d) => DateTime.fromISO(d),
    created_at            : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at            : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at            : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key = 'id' as const;

  routesAttributes = {
    create: {
      name         : 'name',
      slug         : 'slug',
      color        : 'color',
      connection_id: 'connection_id',

      customer_id           : 'settings.customer_id',
      root_container_id     : 'settings.root_container_id',
      campaigns_container_id: 'settings.campaigns_container_id',
      creatives_container_id: 'settings.creatives_container_id',
    },
    save  : {
      name : 'name',
      slug : 'slug',
      color: 'color',

      customer_id           : 'settings.customer_id',
      root_container_id     : 'settings.root_container_id',
      campaigns_container_id: 'settings.campaigns_container_id',
      creatives_container_id: 'settings.creatives_container_id',
    },
  };

}

export class Network extends NetworkModel implements INetwork {
  active!: boolean;

  broadcaster_connection!: BroadcasterConnection;

  color!: string;

  connection_id!: number;

  created_at!: DateTime;

  deleted_at!: DateTime | null;

  id!: number;

  name!: string;

  slug!: string;

  properties_fields!: Collection<Field>;

  updated_at!: DateTime;

  uuid!: string;

  settings!: NetworkSettings;

  last_sync_at!: DateTime | null;

  containers!: Collection<NetworkContainer>;

  locations!: Collection<Location>;

  constructor(attributes: Partial<INetwork> = {}) {
    super();
    this.setAttributes(attributes);
  }

  /**
   * Trigger a synchronization of the network's resources with its attached broadcaster
   */
  synchronize() {
    const path       = preparePathForSingleModelAction(this.basePath, '/_synchronize');
    const parameters = preparePathParameters(path, this);
    return makeRequest(makeRoute(path, HTTPMethod.post), parameters);
  }

  exportProperties(level: string | null) {
    const path         = preparePathForSingleModelAction(this.basePath, '/_dump_properties');
    const parameters   = preparePathParameters(path, this);
    const route        = makeRoute(path, HTTPMethod.get);
    route.responseType = 'arraybuffer';

    return makeRequest(route, parameters, { level })
      .then(handleDownload(`${ this.name }.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ));
  }
}
