/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <vdufois@neo-ooh.com>
 *
 * @neo/connect - Extract.ts
 */

import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { DateTime }                                               from 'luxon';
import Collection                                                 from 'library/Collection';
import ReportStatus                                               from './enums/ReportStatus';
import { ExtractTemplate, GeographicReport, Property }            from './index';
import { ExtractValue }                                           from './ExtractValue';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';

export interface ExtractMetadata {
  executionTimeMs: number | null,
  error: Record<string, any> | null,
}

export interface IExtract {
  id: number,
  uuid: string,
  template_id: number,
  property_id: number,
  geographic_report_id: number,
  bounding_geographic_report_id: number | null,
  metadata: ExtractMetadata,
  status: ReportStatus

  requested_at: DateTime,
  requested_by: number | null,
  processed_at: DateTime,
  deleted_at: DateTime | null,
  deleted_by: number | null,

  template: ExtractTemplate,
  property: Property,
  geographic_report: GeographicReport,
  bounding_geographic_report: GeographicReport | null,
  values: Collection<ExtractValue>,
}

class ExtractModel extends Model<IExtract> {
  _slug = 'extract';

  basePath = '/v1/demographics/extracts';

  attributesTypes: { [attr in keyof IExtract]?: (sourceAttr: any) => IExtract[attr] } = {
    template                  : Model.make(ExtractTemplate),
    property                  : Model.make(Property),
    geographic_report         : Model.make(GeographicReport),
    bounding_geographic_report: Model.make(GeographicReport),
    values                    : Collection.ofType(ExtractValue).make,
    requested_at              : (d) => DateTime.fromISO(d),
    processed_at              : (d) => DateTime.fromISO(d),
    deleted_at                : (d) => DateTime.fromISO(d),
  };

  key: keyof IExtract = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IExtract | string)[] } = {
    create: [ 'property_id', 'template_id', 'geographic_report_id', 'bounding_geographic_report_id', 'metadata', 'status' ],
    save  : [],
  };
}

export class Extract extends ExtractModel implements IExtract {
  id!: number;

  uuid!: string;

  template_id!: number;

  property_id!: number;

  geographic_report_id!: number;

  bounding_geographic_report_id!: number | null;

  metadata!: ExtractMetadata;

  status!: ReportStatus;

  requested_at!: DateTime<true>;

  requested_by!: number | null;

  processed_at!: DateTime<true>;

  deleted_at!: DateTime<true> | null;

  deleted_by!: number | null;

  template!: ExtractTemplate;

  property!: Property;

  geographic_report!: GeographicReport;

  bounding_geographic_report!: GeographicReport | null;

  values!: Collection<ExtractValue>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  async forceProcess() {
    const path       = preparePathForSingleModelAction(this.basePath, `/_force_process`);
    const pathParams = preparePathParameters(path, this);
    const route      = makeRoute(path, HTTPMethod.post);

    await makeRequest<IExtract>(route, pathParams);

    return Promise.all([
      this.invalidate(),
      this.invalidateAll(),
    ]);
  }
}
